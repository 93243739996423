import { useTranslation } from "react-i18next";
import { Img } from "components/Img";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ProgressiveImage from "react-progressive-image";
import "react-lazy-load-image-component/src/effects/blur.css";
import LazyLoad from "react-lazy-load";
function HowDoesItWork(props) {
  return (
    <Stack className="bg-gradient1  h-[1117px]  lg:h-[724px] xl:h-[829px] 2xl:h-[932px]  lg:pl-[14px] xl:px-[30px] 2xl:pl-[18px] pl-[22px] w-[100%]">
      <Img
        src="/images/img_group23.png"
        className="absolute h-[1117px]  lg:h-[724px] xl:h-[829px] 2xl:h-[932px] "
        alt="GroupTwentyThree"
      />
      <Row className="absolute h-[max-content] inset-[0] justify-center m-[auto] ">
        <LazyLoad offset={300}>
          <ProgressiveImage
            src={
              props.hostLink + "/uploads/" + props.Work.images?.[0].file_path
            }
            srcSetData={{
              srcSet: `${
                props.hostLink + "/uploads/" + props.Work.images?.[0].file_path
              }`,
            }}
            placeholder={
              props.hostLink + "/uploads/" + props.Work.images?.[2].file_path
            }
          >
            {(src, _loading, srcSetData) => (
              <Img
                src={src}
                srcSet={srcSetData.srcSet}
                className="lg:h-[618px] xl:h-[706px] 2xl:h-[594px] h-[952px] 3xl:h-[600px] rounded-radius10"
              />
            )}
          </ProgressiveImage>
        </LazyLoad>
        <Column className="items-center  lg:ml-[35px] xl:ml-[40px] 2xl:ml-[45px] ml-[54px]  w-[47%]">
          <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__fadeInLeftBig"
          >
            <Text className="font-bold uppercase lg:text-[31px] xl:text-[39px] 2xl:text-[46px] text-[30px] text-red_900 w-[auto]">
              {props.Work.title}
            </Text>
          </AnimationOnScroll>
          <Column className="items-center lg:mt-[25px] xl:mt-[29px] 2xl:mt-[33px] mt-[40px] w-[100%]">
            <Row
              dangerouslySetInnerHTML={{ __html: props.Work.text }}
              className="w-[100%] block text-[21px] lg:text-[16px]"
            ></Row>
          </Column>
        </Column>
      </Row>
    </Stack>
  );
}
export default HowDoesItWork;
