import "../heartPulsePreloader/heartPulsePreloader.css";
import { useTranslation } from "react-i18next";
import { Img } from "components/Img";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import HeartPulseReloader from "components/heartPulsePreloader/heartPulsePreloader";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import "./header.css";
import { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
function Header(props) {
  const { t } = useTranslation();
  const [Languages, fetchLanguages] = useState([]);
  const getData = () => {
    fetch(`${props.hostLink}/API/api/languages`)
      .then((res) => res.json())
      .then((res) => {
        fetchLanguages(res);
      });

    if (props.language == "") {
      props.setLanguage(1);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [DataInfo, fetchDataInfo] = useState([]);
  const getDataInfo = () => {
    fetch(`${props.hostLink}/API/api/info`)
      .then((res) => res.json())
      .then((res) => {
        fetchDataInfo(res);
      });
  };

  useEffect(() => {
    getDataInfo();
  }, []);

  const [BreakingNews, fetchBreakingNews] = useState([]);
  const getBreakingNews = () => {
    fetch(`${props.hostLink}/API/api/breaking-news?language=` + props.language)
      .then((res) => res.json())
      .then((res) => {
        fetchBreakingNews(res);
      });
  };

  useEffect(() => {
    getBreakingNews();
  }, [props.language]);

  function changedLanguage() {
    var selectedLanguage = $("#selectionLanguage")
      .find("option:selected")
      .val();
    props.setLanguage(selectedLanguage);

    var selectedDirection = $("#selectionLanguage")
      .find("option:selected")
      .data("id");
    props.setDirection(selectedDirection);
  }

  return (
    <header className="w-[100%] fixed top-0 z-50 ">
      <Stack className="2xl:h-[118px] h-[131px] 3xl:h-[130px] lg:h-[90px] xl:h-[92px] shadow-bs w-[100%] ">
        <Column className="absolute items-center right-[0] w-[87%]">
          <Row className="bg-indigo_600 items-center  lg:pl-[110px] xl:pl-[35px] 2xl:pl-[47px] pl-[18px] lg:pr-[300px] xl:pr-[35px] 2xl:pr-[47px] pr-[300px] w-[100%]">
            <div className="!w-[93%] lg:!w-full !relative !right-[3%] groove d-flex justify-content-between align-items-center breaking-news bg-white">
              {/* <div
          style={{
            height:"50px",
            whiteSpace: "nowrap",
            background: "red",
            color: "#fff",
            overflow: "hidden",
            padding: "10px 12px",
            display: "inline-block",
            fontWeight: "bold",
          }}
          className="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2  px-1 news"
        >
          <span className="d-flex align-items-center">News</span>
        </div> */}
              <Marquee
                style={{ backgroundColor: "rgb(44 109 152)" }}
                pauseOnHover={true}
                speed={65}
                className="2xl:h-[50px] xl:h-[50px] lg:h-[50px] md:h-[50px] h-[50px]"
              >
                {BreakingNews.map((breaking, i) => (
                  <p key={i} className="my-[0.5rem] text-white mx-2">
                    <span className="dot"></span>
                    {breaking.description}
                  </p>
                ))}
              </Marquee>
            </div>
            <Img
              src="/images/img_arrowdown.png"
              className="h-[14px]   w-[40px]"
              alt="arrowdown"
            />
            <Text className="mr-10 font-normal ml-[10px] lg:ml-[6px] xl:ml-[7px] 2xl:ml-[8px] not-italic lg:text-[16px] xl:text-[16px] 2xl:text-[16px] text-[16px] text-white_A700 w-[auto]">
              <a className="text-white" href={`mailto:${DataInfo.email}`}>
                {DataInfo.email}
              </a>
            </Text>
            <a target="_blank" href="https://www.facebook.com/medeva.org">
              <i className="bi bi-facebook text-white text-2xl"></i>
            </a>
            <a target="_blank" href="https://www.instagram.com/medevasan/">
              <i className="bi bi-instagram text-white ml-[10px] text-2xl"></i>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/medevasan/"
            >
              <i className="bi bi-linkedin text-white ml-[10px]  text-2xl"></i>
            </a>
            <a target="_blank" href="https://wa.me/+33491321936">
              <i className="bi bi-whatsapp text-white ml-[10px]  text-2xl"></i>
            </a>
            {/* <Img
                  src="/images/img_plus_indigo_600.png"
                  className="lg:h-[32px] xl:h-[37px] 2xl:h-[41px] h-[49px] 3xl:h-[50px] w-[5%]"
                  alt="battery"
                /> */}
          </Row>
          <Row className="bg-white_A700 items-center justify-end lg:px-[46px] xl:px-[52px] 2xl:px-[59px] pl-[71px] w-[100%]">
            <HeartPulseReloader />

            <Link
              onClick={window["carousel"]}
              className="homebtn header_titles font-normal font-productsans lg:mr-[35px] xl:mr-[40px] 2xl:mr-[45px] mr-[19px] not-italic lg:text-[13px] xl:text-[13px] 2xl:text-[13px] text-[13px] text-black_900 w-[auto] uppercase"
              to={{ pathname: `${props.Link}/` }}
            >
              {props.Labels.home}
            </Link>
            <Link
              onClick={window["carousel"]}
              to={{ pathname: `${props.Link}/` }}
              id="aaa"
              className="header_titles services_btn cursor-pointer font-normal font-productsans lg:mr-[35px] xl:mr-[40px] 2xl:mr-[45px] mr-[19px] not-italic lg:text-[13px] xl:text-[13px] 2xl:text-[13px] text-[13px] text-black_900 w-[auto] uppercase"
            >
              {props.Labels.services}
            </Link>

            <Link
              className="header_titles font-normal font-productsans lg:mr-[35px] xl:mr-[40px] 2xl:mr-[45px] mr-[19px] not-italic lg:text-[13px] xl:text-[13px] 2xl:text-[13px] text-[13px] text-black_900 w-[auto] uppercase"
              to={{ pathname: `${props.Link}/branches` }}
            >
              {props.Labels.branches}
            </Link>
            <Link
              className="header_titles font-normal font-productsans lg:mr-[35px] xl:mr-[40px] 2xl:mr-[45px] mr-[19px] not-italic lg:text-[13px] xl:text-[13px] 2xl:text-[13px] text-[13px] text-black_900 w-[auto] uppercase"
              to={{ pathname: `${props.Link}/our_partners` }}
            >
              {props.Labels.partners}
            </Link>

            <Link
              className="header_titles font-normal font-productsans lg:mr-[35px] xl:mr-[40px] 2xl:mr-[45px] mr-[19px] not-italic lg:text-[13px] xl:text-[13px] 2xl:text-[13px] text-[13px] text-black_900 w-[auto] uppercase"
              to={{ pathname: `${props.Link}/news` }}
            >
              {props.Labels.news}
            </Link>
            <Link
              className="text-center header_titles font-normal font-productsans lg:mr-[35px] xl:mr-[40px] 2xl:mr-[45px] mr-[19px] not-italic lg:text-[13px] xl:text-[13px] 2xl:text-[13px] text-[13px] text-black_900 w-[auto] uppercase"
              to={{ pathname: `${props.Link}/contact_us` }}
            >
              {props.Labels.contact_us}
            </Link>

            <div className=" lg:h-[20px] xl:h-[23px] 2xl:h-[26px] h-[30px] 3xl:h-[31px] rounded-radius1508 lg:w-[19px] xl:w-[22px] 2xl:w-[25px] w-[10px]"></div>

            <div
              className={`flex justify-center pt-2  ${
                props.language == "Ar"
                  ? "float-right  sm:float-left"
                  : "float-left  sm:float-right"
              }`}
            >
              <div className="relative top-[10px] flex">
                <i className="bi bi-globe text-black text-xl "></i>
                <select
                  onChange={changedLanguage}
                  id="selectionLanguage"
                  className="cursor-pointer h-max mt-4 !bg-none  !border-transparent !text-black
    appearance-none
    block
    w-full
    px-1
    sm:text-xl
    font-normal
    !bg-transparent bg-clip-padding bg-no-repeat
    relative !bottom-6
    rounded
    transition
    ease-in-out
    !m-0 !p-0
    focus:text-gray-700 focus:bg-transparent focus:!shadow-transparent  focus:outline-none"
                  aria-label=".form-select-lg example"
                >
                  {Languages.map((language, i) => {
                    return language.id == props.language ? (
                      <option
                        key={i}
                        className="text-slate-400"
                        selected
                        value={`${language.id}`}
                        data-id={`${language.direction}`}
                      >
                        {" "}
                        {language.name}
                      </option>
                    ) : (
                      <option
                        key={i}
                        className="text-slate-400"
                        value={`${language.id}`}
                        data-id={`${language.direction}`}
                      >
                        {" "}
                        {language.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* <Text className=" font-bold font-roboto xl:ml-[3px] lg:ml-[3px] 2xl:ml-[4px] ml-[5px] xl:text-[10px] 2xl:text-[12px] text-[14.56px] 3xl:text-[14px] lg:text-[9px] text-black_900 w-[auto]">
                  EN
                </Text>
                <Img
                  src="/images/img_vectorstroke.png"
                  className="xl:h-[4px] lg:h-[4px] h-[5px] 3xl:h-[6px] xl:ml-[3px] lg:ml-[3px] 2xl:ml-[4px] ml-[5px] w-[1%]"
                  alt="VectorStroke"
                /> */}
            <Column className="bg-white_900 lg:h-[80px] xl:h-[80px] 2xl:h-[80px] h-[80px] 3xl:h-[80px] items-center ">
              {/* <div className="bg-white_A700 h-[2px] 3xl:h-[3px] mt-[3px] rounded-radius1 w-[97%]"></div>
                  <div className="bg-white_A700 h-[2px] 3xl:h-[3px] lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] mt-[7px] rounded-radius1 w-[97%]"></div>
                  <div className="bg-white_A700 h-[2px] 3xl:h-[3px] mb-[3px] lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] mt-[7px] rounded-radius1 w-[97%]"></div> */}
            </Column>
          </Row>
        </Column>
        <Link
          className="absolute z-50"
          onClick={window["carousel"]}
          to={{ pathname: `${props.Link}/` }}
        >
          <Img
            src="/images/img_group35.png"
            className="z-50 absolute    lg:ml-[100px] lg:w-[33%] ml-[30px] w-[50%] mt-[10px]"
            alt="FrameOne"
          />
          <div className="corner-top-right-bevel w-[200px] lg:w-[320px]"></div>
        </Link>
      </Stack>
    </header>
  );
}
export default Header;
