import { Column, Stack, Row, Img, Text, Button } from "components";
import { Link, useParams } from "react-router-dom";

const Services_singlepage = (props) => {
  let { id } = useParams();
  props.fetchid(id);

  return (
    <>
      {props.isLoading ? (
        <div
          className="spinner-border text-primary  mt-[300px] relative  ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <Column
          style={{ direction: props.direction == 2 ? "rtl" : "ltr" }}
          className="bg-white_A700 font-productsans items-center mx-[auto] pb-[1px] w-[100%]"
        >
          {props.ServicesSingle.map((service, i) => {
            return (
              <Stack key={i} className=" w-[100%]">
                <Column
                  style={{
                    background:
                      "linear-gradient(to left, rgba(255,255,255,0.3) 20%,rgba(255,255,255,1)), url(/images/img_component12.jpg)",
                  }}
                  className=" !bg-no-repeat !bg-center !bg-cover justify-end xl:p-[103px] 2xl:p-[115px] 3xl:p-[139px] lg:p-[90px] p-[90px]  top-[0] w-[100%]"
                >
                  <Text className="font-bold lg:ml-[42px] ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] xl:mt-[3px] lg:mt-[3px] mt-[3px] 2xl:mt-[4px] 3xl:mt-[5px]  lg:text-[45px] text-[65px] xl:text-[51px] 2xl:text-[58px] 3xl:text-[70px] text-indigo_600 w-[auto]">
                    {service.name}
                  </Text>
                  <Row className="items-center lg:ml-[42px] ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] mr-[auto] lg:mt-[12px] mt-[12px] xl:mt-[14px] 2xl:mt-[15px] 3xl:mt-[19px]  w-[18%]">
                    <Img
                      src="/images/img_home.svg"
                      className="2xl:h-[11px] 3xl:h-[13px] lg:h-[8px] h-[18px] xl:h-[9px] 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[17px] xl:w-[8px]"
                      alt="Home Five"
                    />
                    <Link
                      onClick={window["carousel"]}
                      to={{ pathname: `${props.Link}/` }}
                    >
                      <Text className="font-normal xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[14px] lg:ml-[9px] ml-[9px] not-italic lg:text-[10px] text-[25px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-gray_700 w-[auto]">
                        {props.Labels.home}
                      </Text>
                    </Link>
                    <Img
                      src="/images/img_arrowright_gray_700.svg"
                      className="3xl:h-[11px] lg:h-[7px] h-[17px] xl:h-[8px] 2xl:h-[9px] xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[14px] lg:ml-[9px] ml-[9px] lg:w-[2%] w-[11%]"
                      alt="arrowright Eight"
                    />
                    <Text className="font-normal 2xl:ml-[10px] 3xl:ml-[13px] lg:ml-[8px] ml-[8px] xl:ml-[9px] not-italic lg:text-[10px] text-[25px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-indigo_600 w-[auto]">
                      {service.name}
                    </Text>
                  </Row>
                </Column>
                <Row
                  className=" bg-cover bg-repeat bottom-[13%] 3xl:p-[111px] lg:p-[71px] p-[80px] xl:p-[82px] 2xl:p-[92px] top-[14%] w-[100%]"
                  style={{ backgroundImage: "url('images/img_group16.png')" }}
                >
                  <div className="grid grid-cols-3 gap-4">
                    <Column className=" lg:ml-[46px] xl:ml-[52px] 2xl:ml-[59px] 3xl:ml-[71px] lg:col-span-1 col-span-3   order1 ">
                      <Column className="bg-gray_100 items-center justify-center lg:p-[20px] p-[20px] xl:p-[22px] 2xl:p-[25px] 3xl:p-[31px] rounded-radius10  lg:w-[85%] w-[100%]">
                        {props.AllServices.map((Allservice, i) => {
                          return (
                            <Link
                              key={i}
                              className="w-full text-black"
                              to={{
                                pathname: `${props.Link}/service-singlepage/${Allservice.id}`,
                              }}
                            >
                              <Row className="bg-white_A700 items-center justify-between cursor-pointer mt-[2px] lg:p-[12px] p-[12px] xl:p-[14px] 2xl:p-[16px] 3xl:p-[20px] rounded-radius5 w-[100%]">
                                <Text className="font-normal my-[3px] not-italic lg:text-[10px] text-[12px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] w-[auto]">
                                  {Allservice.name}
                                </Text>
                                <Img
                                  src="/images/img_arrowright_black_900.svg"
                                  className=" w-[2%]"
                                  alt="arrowright"
                                />
                              </Row>
                            </Link>
                          );
                        })}
                      </Column>
                      <Stack className="bg-gray_100 lg:mt-[32px] mt-[32px] xl:mt-[37px] 2xl:mt-[41px] 3xl:mt-[50px] rounded-radius10 lg:w-[85%] w-[100%]">
                        <Stack className=" w-[%]">
                          <Column className=" right-[0] w-[100%]">
                            <Stack className="w-[100%]">
                              <Img
                                src="/images/contact_us_card.png"
                                className="w-[100%] lg:h-[500px] h-[400px]  "
                                alt="dotsworldmap"
                              />
                              <Text className="absolute font-bold inset-x-[0] leading-[normal] mx-[auto] lg:text-[19px] text-[19px] xl:text-[22px] 2xl:text-[25px] 3xl:text-[30px] text-teal_900 top-[3%] w-[87%]">
                                We Are Ready 24/7, Whenever You Might Need
                                Assistance
                              </Text>
                              <Link
                                className="top-[13%] lg:top-[25%]  w-[87%] absolute"
                                to={{ pathname: `${props.Link}/contact_us` }}
                              >
                                <Button
                                  className=" 2xl:ml-[16px]  3xl:ml-[20px]   flex items-center justify-center left-[5%] lg:ml-[12px] ml-[60px]  text-center  xl:ml-[14px]"
                                  rightIcon={
                                    <Img
                                      src="/images/img_minimize.svg"
                                      className="!object-contain text-center left-[2%]  lg:w-[5px] w-[5px]  lg:ml-[6px] ml-[6px] xl:w-[6x] xl:h-[6px] xl:ml-[7px] 2xl:w-[5px] 2xl:h-[6px] 2xl:ml-[8px] 3xl:w-[7px] 3xl:h-[8px] 3xl:ml-[10px]"
                                      alt="minimize"
                                    />
                                  }
                                  shape="RoundedBorder5"
                                  size="sm"
                                  variant="FillIndigo600"
                                >
                                  <div className="bg-transparent font-bold left-[0] 2xl:text-[10px] 3xl:text-[12px] lg:text-[7px] text-[7px] xl:text-[8px] top-[0]">
                                    CONTACT US
                                  </div>
                                </Button>
                              </Link>
                            </Stack>
                            {/* <Img
                        src="/images/img_vector.svg"
                        className="lg:h-[181px] xl:h-[207px] 2xl:h-[233px] 3xl:h-[280px] lg:mt-[20px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[31px] w-[80%]"
                        alt="Vector One"
                      /> */}
                          </Column>
                          {/* <Img
                      src="/images/img_kisspnghealth.png"
                      className=" bottom-[0]  right-[0] w-[83%]"
                      alt="kisspnghealth"
                    /> */}
                        </Stack>
                      </Stack>
                    </Column>
                    <Column className="lg:col-span-2 col-span-3  order2">
                      {service.images.map(function (images, i) {
                        return (
                          <Img
                            key={i}
                            src={props.hostLink + images.file_path}
                            className="mb-10 lg:max-h-[622px] max-h-[622px] xl:max-h-[668px] 2xl:max-h-[714px] 3xl:max-h-[797px] rounded-radius10 w-[100%]"
                            alt="FrameNinetyTwo"
                          />
                        );
                      })}
                      <Row className="items-center lg:mt-[22px] mt-[22px] xl:mt-[25px] 2xl:mt-[29px] 3xl:mt-[35px] ">
                        {/* <Img
                    src="/images/img_videocamera.svg"
                    className="lg:h-[26px] xl:h-[30px] 2xl:h-[34px] 3xl:h-[41px] lg:w-[25px] xl:w-[29px] 2xl:w-[33px] 3xl:w-[40px]"
                    alt="videocamera"
                  /> */}
                        <Text className="font-bold lg:text-[32px] text-[36px] xl:text-[37px] 2xl:text-[41px] 3xl:text-[50px] text-indigo_600 w-[auto]">
                          {service.name}
                        </Text>
                      </Row>
                      <Row className="lg:mt-[13px] mt-[13px] xl:mt-[15px] 2xl:mt-[17px] 3xl:mt-[21px] w-[100%]">
                        {/* <div className="mt-[5px] bg-red_900 lg:h-[11px] h-[11px] xl:h-[12px] 2xl:h-[14px] 3xl:h-[17px] mt-[1px] rounded-radius50 lg:w-[10px] w-[10px] xl:w-[11px] 2xl:w-[13px] 3xl:w-[16px]"></div> */}
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}
                          className="font-normal leading-[normal] 3xl:ml-[10px] lg:ml-[6px] ml-[6px] xl:ml-[7px] 2xl:ml-[8px] not-italic lg:text-[16px] text-[18px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[25px] text-black_900 w-[97%]"
                        ></Text>
                      </Row>
                    </Column>
                  </div>
                </Row>
              </Stack>
            );
          })}
        </Column>
      )}
    </>
  );
};

export default Services_singlepage;
