import { useTranslation } from "react-i18next";
import { Img } from "components/Img";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import { Button } from "components/Button";
import { useState } from "react";
import { useEffect } from "react";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
function LogisticAndMedicalExpertise(props) {
  const split_file_path = props.Logistics.images?.[0].file_path.split("/");

  if (split_file_path) {
    if (split_file_path.length > 0) {
      var blur_file_path = "";
      for (var i = 0; i < split_file_path.length - 1; i++) {
        blur_file_path = blur_file_path.concat(split_file_path[i], "/");
      }
      blur_file_path = blur_file_path.concat(
        "blures/",
        split_file_path[split_file_path.length - 1]
      );
    }
  }
  return (
    <Stack className=" w-[100%]">
      <Column className=" items-center w-[100%]">
        <Column className="bg-white_A700 items-center lg:p-[46px] xl:p-[52px] 2xl:p-[59px] p-[71px] w-[100%]">
          <Text className="font-bold lg:leading-[51px] xl:leading-[59px] 2xl:leading-[66px] leading-[80.00px] 3xl:leading-[80px] lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] mt-[9px] lg:text-[45px] xl:text-[51px] 2xl:text-[58px] text-[70px] text-center text-indigo_600 w-[66%]">
            {props.Logistics.title}
          </Text>
          <Img
            src="/images/img_group32.png"
            className="3xl:h-[10px] lg:h-[6px] xl:h-[7px] 2xl:h-[8px] h-[9px] lg:mt-[19px] xl:mt-[22px] 2xl:mt-[25px] mt-[30px] w-[35%]"
            alt="GroupThirtyTwo"
          />
          <Column className="relative right-1/4 lg:mb-[109px] xl:mb-[125px] 2xl:mb-[140px] mb-[169px] lg:mt-[51px] xl:mt-[59px] 2xl:mt-[66px] mt-[80px] w-[50%]">
            <Column
              className="bg-cover bg-repeat items-center xl:p-[3px] lg:p-[3px] 2xl:p-[4px] p-[5px] w-[79%]"
              style={{ backgroundImage: "url('/images/img_frame62.png')" }}
            >
              <Text className="font-bold lg:mb-[18px] xl:mb-[20px] 2xl:mb-[23px] mb-[28px] lg:text-[25px] xl:text-[29px] 2xl:text-[33px] text-[36px] text-white_A700 w-[auto]">
                {props.Logistics.brief_description}
              </Text>
            </Column>
            <Row
              dangerouslySetInnerHTML={{ __html: props.Logistics.text }}
              className="xl:mt-[11px] 2xl:mt-[12px] mt-[15px] lg:mt-[9px] w-[100%] block"
            ></Row>
          </Column>
        </Column>
      </Column>
      <LazyLoad offset={300}>
        <ProgressiveImage
          src={`${props.hostLink}/uploads/${props.Logistics.images?.[0].file_path}`}
          placeholder={`${props.hostLink}/uploads/${blur_file_path}`}
        >
          {(src) => (
            <Img
              src={src}
              className="absolute lg:h-[401px] xl:h-[469px] 2xl:h-[556px] h-[784px] mt-10  right-[4%] rounded-radius10 top-[28%] lg:top-[27%] 2xl:top-[22%] w-[38%]"
              alt="FrameSixtyThree"
            />
          )}
        </ProgressiveImage>
      </LazyLoad>
    </Stack>
  );
}
export default LogisticAndMedicalExpertise;
