import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
function OurPartners(props) {
  const split_file_path =
    props.DataPartnersTitle.images?.[0].file_path.split("/");
  if (split_file_path) {
    if (split_file_path.length > 0) {
      var blur_file_path = "";
      for (var i = 0; i < split_file_path.length - 1; i++) {
        blur_file_path = blur_file_path.concat(split_file_path[i], "/");
      }
      blur_file_path = blur_file_path.concat(
        "blures/",
        split_file_path[split_file_path.length - 1]
      );
    }
  }

  return (
    <>
      {props.isLoading ? (
        <div
          className="mt-[300px]  spinner-border text-primary  relative ml-auto mr-auto !block"
          role="status"
        >
          <span className="visually-hidden ">Loading...</span>
        </div>
      ) : (
        <div style={{ direction: props.direction == 2 ? "rtl" : "ltr" }}>
          <Column>
            <ProgressiveImage
              src={
                props.hostLink + props.DataPartnersTitle.images?.[0].file_path
              }
              placeholder={props.hostLink + blur_file_path}
            >
              {(src) => (
                <span
                  style={{
                    backgroundImage: `url(${src})`,
                  }}
                  className="!bg-no-repeat !bg-center !bg-cover justify-end xl:p-[175px] 2xl:p-[175px] 3xl:p-[200px] lg:p-[150px] p-[150px] top-[0] w-[100%]"
                />
              )}
            </ProgressiveImage>
            <span className="absolute xl:p-[103px] 2xl:p-[115px] 3xl:p-[139px] lg:p-[90px] p-[90px]">
              <Text className="font-bold lg:ml-[45px] ml-[45px] xl:ml-[51px] 2xl:ml-[58px] 3xl:ml-[70px] xl:mt-[3px] lg:mt-[3px] mt-[3px] 2xl:mt-[4px] 3xl:mt-[5px] lg:text-[45px] text-[45px] xl:text-[51px] 2xl:text-[58px] 3xl:text-[70px] text-white w-[auto]">
                {props.DataPartnersTitle.title}
              </Text>
              <Row className="items-center lg:ml-[42px] ml-[42px] xl:ml-[48px] 2xl:ml-[54px] 3xl:ml-[65px] mr-[auto] lg:mt-[12px] mt-[12px] xl:mt-[14px] 2xl:mt-[15px] 3xl:mt-[19px] w-[23%]">
                <i className="bi bi-house-door-fill  xl:ml-[10px] 2xl:ml-[11px] text-white 3xl:ml-[14px] lg:ml-[9px] ml-[9px] w-[3%]"></i>
                <Link
                  onClick={window["carousel"]}
                  to={{ pathname: `${props.Link}/` }}
                >
                  <Text className="font-normal xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[14px] lg:ml-[9px] ml-[9px] not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-white w-[auto]">
                    {props.Labels.home}
                  </Text>
                </Link>

                <i className="bi bi-arrow-right text-white xl:ml-[10px] 2xl:w-[10px] 3xl:w-[12px] lg:w-[7px] w-[7px] xl:w-[8px]"></i>
                <Text className="font-normal 2xl:ml-[10px] 3xl:ml-[13px] lg:ml-[8px] ml-[8px] xl:ml-[9px] not-italic lg:text-[10px] text-[10px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[16px] text-white w-[auto]">
                  {props.DataPartnersTitle.title}
                </Text>
              </Row>
            </span>
          </Column>
          <div className="container mt-32">
            <div className="row">
              {props.DataPartners.map((partners, i) => {
                const split_file_path1 = partners.file_path.split("/");
                if (split_file_path1) {
                  if (split_file_path1.length > 0) {
                    var blur_file_path1 = "";
                    for (var i = 0; i < split_file_path1.length - 1; i++) {
                      blur_file_path1 = blur_file_path1.concat(
                        split_file_path1[i],
                        "/"
                      );
                    }
                    blur_file_path1 = blur_file_path1.concat(
                      "blures/",
                      split_file_path1[split_file_path1.length - 1]
                    );
                  }
                }
                return (
                  <div key={i} className="col-md-4 col-sm-6 col-xs-12">
                    <a target="_blank" href={partners.link}>
                      <LazyLoad offset={300}>
                        <ProgressiveImage
                          src={`${props.hostLink + partners.file_path}`}
                          placeholder={`${props.hostLink + blur_file_path1}`}
                        >
                          {(src) => (
                            <img
                              className="mt-10 w-[80%] h-[80%] !object-contain"
                              src={src}
                            />
                          )}
                        </ProgressiveImage>
                      </LazyLoad>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default OurPartners;
