import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import { Button } from "components/Button";
import { List } from "components/List";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
function ServicesPage(props) {
  return (
    <Stack className="services  w-[100%]">
      <Stack className="mt-[140px] lg:mt-[50px] bg-indigo_600 top-[0] w-[100%]">
        <Button
          className="relative left-[43%] mt-3  font-bold inset-x-[0] mx-[auto] text-[20px] lg:text-[15px] xl:text-[16px] 2xl:text-[18px]  text-center top-[0] w-[15%]"
          shape="RoundedBorder5"
          size="sm"
          variant="FillWhiteA700d8"
        >
          {props.ServicesTitle.title}
        </Button>
        <Column
          className=" bg-cover bg-repeat bottom-[0] items-center lg:p-[38px] xl:p-[44px] 2xl:p-[50px]  p-[38px] w-[100%]"
          style={{ backgroundImage: "url('/images/img_group4.png')" }}
        >
          <Text className="font-normal not-italic text-[32px] lg:text-[32px] xl:text-[37px] lg:mt-[20px] mt-[30px] 2xl:text-[41px] text-[50px] text-white_A700 w-[auto]">
            {props.ServicesTitle.sub_title}
          </Text>
          <List
            className="lg:gap-[14px] xl:gap-[16px] 2xl:gap-[18px] gap-[22px] grid grid-cols-2 lg:grid-cols-4  min-h-[auto] lg:mt-[25px] xl:mt-[29px] 2xl:mt-[33px] mt-[40px] w-[99%]"
            orientation="horizontal"
          >
            {props.ServicesData.map((service, i) => {
              const split_file_path = service.file_path.split("/");
              if (split_file_path.length > 0) {
                var blur_file_path = "";
                for (var i = 0; i < split_file_path.length - 1; i++) {
                  blur_file_path = blur_file_path.concat(
                    split_file_path[i],
                    "/"
                  );
                }
                blur_file_path = blur_file_path.concat(
                  "blures/",
                  split_file_path[split_file_path.length - 1]
                );
              }

              return (
                <Link
                  key={i}
                  to={{ pathname: `service-singlepage/${service.id}` }}
                >
                  <Column className="xl:h-[400px] lg:h-[400px] md:h-[350px] h-[500px] bg-white_A700 items-center lg:pb-[20px] xl:pb-[23px] 2xl:pb-[26px] pb-[32px] rounded-radius10 shadow-bs2 w-[100%]">
                    <div
                      className="image"
                      role="img"
                      aria-label="Image description"
                    >
                      <LazyLoad offset={300}>
                        <ProgressiveImage
                          src={`${props.hostLink}/${service.file_path}`}
                          placeholder={`${props.hostLink}/${blur_file_path}`}
                        >
                          {(src) => (
                            <Column
                              className="inner lg:mt-[-130px] xl:mt-[-149px] 2xl:mt-[-168px] mt-[-202px]  bg-cover bg-repeat justify-end p-[0] w-[100%]"
                              style={{
                                backgroundImage: `url(${src})`,
                              }}
                            >
                              <Button className="!bg-transparent item rotate-y relative top-[71%] flex lg:h-[54px] xl:h-[61px] 2xl:h-[69px] h-[82px] 3xl:h-[83px] items-center justify-center lg:ml-[18px] xl:ml-[21px] 2xl:ml-[24px] ml-[29px] lg:mt-[130px] xl:mt-[149px] 2xl:mt-[168px] mt-[202px] lg:w-[53px] xl:w-[60px] 2xl:w-[68px] w-[82px]"></Button>
                            </Column>
                          )}
                        </ProgressiveImage>
                      </LazyLoad>
                    </div>
                    <Column className="lg:mt-[12px] xl:mt-[14px] 2xl:mt-[16px] mt-[20px] w-[84%]">
                      <Text className="font-bold lg:text-[19px] xl:text-[22px]  text-[30px] text-indigo_600 w-[auto]">
                        {service.name}
                      </Text>
                      <Text className="font-normal leading-[normal] mt-[10px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] not-italic lg:text-[12px] xl:text-[14px] 2xl:text-[16px] text-[20px] text-black_900 w-[100%]">
                        {service.breif_description} …
                      </Text>
                      <Row className="items-center lg:mt-[16px] xl:mt-[18px] 2xl:mt-[20px] mt-[25px] rounded-radius5 w-[36%]">
                        <span
                          to={{ pathname: `${props.Link}/service-singlepage` }}
                          className="font-bold lg:text-[10px] xl:text-[11px] 2xl:text-[13px] text-[16px] text-red_900 w-[auto]"
                        >
                          READ MORE
                        </span>
                        <i className="bi bi-plus !text-[#be123c]"></i>
                      </Row>
                    </Column>
                  </Column>
                </Link>
              );
            })}
          </List>
        </Column>
      </Stack>
    </Stack>
  );
}
export default ServicesPage;
