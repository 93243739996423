import { useState } from "react";
import "./cookies.css";
function Cookies(props) {
  const [hide, setHide] = useState(false);
  const onClick = () => {
    setHide(true);
    // console.log(
    //   document.cookie ? document.cookie : "also no cookies found here!"
    // );
  };

  /*reject the cookies*/
  const [accepted, setAccepted] = useState(true);
  function handleReject() {
    // delete all cookies
    document.cookie.split(";").forEach(function (e) {
      document.cookie = e
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    // console.log(document.cookie ? document.cookie : "no cookies found");
    localStorage.setItem("cookie_policy", "rejected");
    setAccepted(false);
    setHide(true);
  }
  return (
    <>
      {!hide ? (
        <div className="cookie-notice">
          <p> {props.Labels.cookies}</p>{" "}
          <button
            onClick={onClick}
            type="button"
            className="cookie-btn left-cookie-btn"
          >
            Accept All
          </button>
          <button
            onClick={handleReject}
            type="button"
            className="cookie-btn right-cookie-btn"
            style={{ backgroud: "#Fff9f9" }}
          >
            Reject All
          </button>
        </div>
      ) : null}
    </>
  );
}
export default Cookies;
