import { Img } from "components/Img";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import "./MedicalTransportAndServices.css";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
function MedicalTransportAndServices(props) {
  const split_file_path = props.Medical.images?.[1].file_path.split("/");
  if (split_file_path) {
    if (split_file_path.length > 0) {
      var blur_file_path = "";
      for (var i = 0; i < split_file_path.length - 1; i++) {
        blur_file_path = blur_file_path.concat(split_file_path[i], "/");
      }
      blur_file_path = blur_file_path.concat(
        "blures/",
        split_file_path[split_file_path.length - 1]
      );
    }
  }

  return (
    <>
      <Stack className="lg:mt-[50px] mt-[50px] w-[100%]">
        <Column className=" bg-cover bg-indigo_600 bg-repeat items-center 2xl:p-[110px] p-[132px] lg:p-[85px] xl:p-[97px] top-[0] w-[100%]">
          <img
            className="absolute h-[586px] right-[0] top-[-33px] opacity-[0.1]"
            src="/images/earth.gif"
          />

          <img
            className="mb-[30px] !object-contain left-[35%] lg:left-1/4 relative mt-[10px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px]  h-[150px] w-[150px]"
            src={props.hostLink + props.Medical.images?.[0].file_path}
          />

          <Text className="w-[40%] text-center   left-[35%] lg:left-1/4  relative font-bold lg:text-[35px] xl:text-[40px] 2xl:text-[39px] text-[26px] text-white_A700 w-[auto]">
            {props.Medical.title}
          </Text>
          {/* <Text className="left-[35%] lg:left-1/4 relative font-normal mt-[10px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] not-italic lg:text-[24px] xl:text-[28px] 2xl:text-[31px] text-[22px] text-white_A700 w-[auto]">
        From Any Location In The World
        </Text> */}
          <Row
            dangerouslySetInnerHTML={{ __html: props.Medical.text }}
            className="medical_transport_title left-[35%] lg:left-1/4 relative items-center justify-end  lg:mt-[25px] xl:mt-[29px] 2xl:mt-[33px] mt-[25px] "
          ></Row>
        </Column>
        <LazyLoad offset={300}>
          <ProgressiveImage
            src={props.hostLink + props.Medical.images?.[1].file_path}
            placeholder={props.hostLink + blur_file_path}
            className="absolute bottom-[0] lg:h-[300px]  xl:h-[343px] 2xl:h-[386px] h-[462px] 3xl:h-[463px] left-[12%] top-[80px] lg:top-[120px] rounded-radius10  lg:w-[26%] w-[40%]"
          >
            {(src) => (
              <Img
                src={src}
                className="absolute bottom-[0] lg:h-[300px]  xl:h-[343px] 2xl:h-[386px] h-[462px] 3xl:h-[463px] left-[12%] top-[80px] lg:top-[120px] rounded-radius10  lg:w-[26%] w-[40%]"
              />
            )}
          </ProgressiveImage>
        </LazyLoad>
      </Stack>
    </>
  );
}
export default MedicalTransportAndServices;
