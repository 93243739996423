import { useTranslation } from "react-i18next";
import { Img } from "components/Img";
import { Column } from "components/Column";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Stack } from "components/Stack";
import { Button } from "components/Button";
import "./WhoWeAre.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
// import { AnimationOnScroll } from 'react-animation-on-scroll';
// import AOS from "aos";

function WhoWeAre(props) {
  const boxVariant = {
    visible: { opacity: 1, scale: 1.1, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <Stack className=" bg-gradient  bottom-[0] w-[100%]">
      <Img
        src="/images/img_vector.png"
        className="absolute bottom-[0] lg:h-[186px] h-[186px] xl:h-[213px] 2xl:h-[239px] h-[286px] 3xl:h-[287px] w-[100%]"
        alt="Vector One"
      />

      <Row
        className=" bg-cover bg-repeat lg:p-[40px] p-[40px] xl:p-[45px] 2xl:p-[51px]  w-[100%]"
        style={{ backgroundImage: "url('/images/img_group5.png')" }}
      >
        <Column className="whoAreWE lg:ml-[5px] ml-[5px] xl:ml-[6px] 2xl:ml-[7px] ml-[9px] lg:mt-[49px] mt-[49px] xl:mt-[57px] 2xl:mt-[64px] mt-[77px] 2xl:pr-[105px] pr-[127px] lg:pr-[82px] pr-[82px] xl:pr-[94px] w-[67%]">
          <Text className="font-bold lg:text-[45px] text-[45px] xl:text-[55px] 2xl:text-[60px] text-[55px] text-red_900 w-[auto]">
            {props.WhoWeAre.title}
          </Text>

          <Text className="font-normal mb-[10px] not-italic lg:text-[20px] text-[25px] xl:text-[24px] 2xl:text-[28px]  text-indigo_600 w-[100%]">
            {props.WhoWeAre.brief_description}
          </Text>
          <Text
            dangerouslySetInnerHTML={{ __html: props.WhoWeAre.text }}
            className="font-normal  not-italic lg:text-[16px] text-[21px] xl:text-[18px] 2xl:text-[20px]  text-gray_700 w-[100%]"
          ></Text>

          {props.Records.map((record, i) => (
            <Row key={i} className="items-center mt-[10px] w-[100%]">
              <Img
                src="/images/img_checkmark.png"
                className="lg:h-[14px] h-[14px] xl:h-[16px] 2xl:h-[18px] h-[21px] 3xl:h-[22px] w-[3%] !object-contain"
                alt="checkmark"
              />

              <Text className=" font-bold 2xl:ml-[10px] ml-[12px] lg:ml-[7px] ml-[7px] xl:ml-[8px] lg:text-[16px] text-[20px] xl:text-[18px] 2xl:text-[20px]  text-black_900 w-[auto]">
                {record.before_record} &nbsp;
                <CountUp end={record.record} redraw={false}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <span> {record.after_record}</span>
              </Text>
            </Row>
          ))}

          <Row className="items-center mt-[10px] w-[25%]">
            <Button
              className="!p-[5px] flex lg:h-[52px] h-[52px] xl:h-[60px] 2xl:h-[67px]  3xl:h-[81px] items-center justify-center rounded-radius50 lg:w-[51px] w-[51px] xl:w-[59px] 2xl:w-[66px]"
              size="mdIcn"
              variant="icbFillIndigo600"
            >
              <Img
                src="/images/img_globe.png"
                className="flex items-center justify-center"
                alt="globe"
              />
            </Button>
            <Button
              className="!p-[5px] flex lg:h-[52px] h-[52px]  xl:h-[60px] 2xl:h-[67px]  3xl:h-[81px] items-center justify-center lg:ml-[25px] ml-[25px] xl:ml-[29px] 2xl:ml-[33px]  rounded-radius50 lg:w-[51px] w-[51px] xl:w-[59px] 2xl:w-[66px] "
              variant="icbFillIndigo600"
            >
              <Img
                src="/images/img_creditcard.png"
                className="flex items-center justify-center"
                alt="creditcard"
              />
            </Button>
          </Row>
        </Column>
        {props.language == 1 ? (
          <motion.div
            className="box lg:mb-[16px] mb-[16px] xl:mb-[18px] 2xl:mb-[20px] mb-[25px] lg:mt-[11px] mt-[11px] xl:mt-[13px] 2xl:mt-[55px] mt-[18px] w-[41%]"
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
          >
            <Stack className="absolute  h-[500px]  top-[0] w-[100%]">
              <LazyLoad offset={300}>
                <ProgressiveImage
                  src={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[0].file_path
                  }
                  placeholder={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[2].file_path
                  }
                  className="absolute lg:h-[235px] bottom-[10%] lg:bottom-[10%]  h-[300px]  right-[0] rounded-radius10 top-[30%] w-[60%]"
                >
                  {(src) => (
                    <Img
                      src={src}
                      className="absolute lg:h-[235px] bottom-[10%] lg:bottom-[10%]  h-[300px]  right-[0] rounded-radius10 top-[30%] w-[60%]"
                    />
                  )}
                </ProgressiveImage>
              </LazyLoad>
              <LazyLoad offset={300}>
                <ProgressiveImage
                  src={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[3].file_path
                  }
                  placeholder={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[5].file_path
                  }
                  className="absolute  bottom-[0] md:bottom-[0] lg:bottom-[15%] xl:bottom-[5%]  2xl:bottom-[5%] 3xl:bottom-[5%] lg:h-[235px] top-[80%] lg:top-[65%] h-[300px] left-[0] rounded-radius10 w-[49%]"
                >
                  {(src) => (
                    <Img
                      src={src}
                      className="absolute  bottom-[0] md:bottom-[0] lg:bottom-[15%] xl:bottom-[5%]  2xl:bottom-[5%] 3xl:bottom-[5%] lg:h-[235px] top-[80%] lg:top-[65%] h-[300px] left-[0] rounded-radius10 w-[49%]"
                    />
                  )}
                </ProgressiveImage>
              </LazyLoad>
            </Stack>
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={
                  props.hostLink +
                  "/uploads/" +
                  props.WhoWeAre.images?.[6].file_path
                }
                placeholder={
                  props.hostLink +
                  "/uploads/" +
                  props.WhoWeAre.images?.[8].file_path
                }
                className="absolute bottom-[15%]  lg:bottom-[0%] xl:bottom-[12%]  2xl:bottom-[12%] 3xl:bottom-[5%] lg:h-[235px]  h-[300px] right-[9%] rounded-radius10 w-[55%]"
              >
                {(src) => (
                  <Img
                    src={src}
                    className="absolute bottom-[15%]  lg:bottom-[0%] xl:bottom-[12%]  2xl:bottom-[12%] 3xl:bottom-[5%] lg:h-[235px]  h-[300px] right-[9%] rounded-radius10 w-[55%]"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
          </motion.div>
        ) : (
          <motion.div
            className="box lg:mb-[16px] mb-[16px] xl:mb-[18px] 2xl:mb-[20px] mb-[25px] lg:mt-[11px] mt-[11px] xl:mt-[13px] 2xl:mt-[55px] mt-[18px] w-[41%]"
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
          >
            <Stack className="absolute  h-[500px]  top-[0] w-[100%]">
              <LazyLoad offset={300}>
                <ProgressiveImage
                  src={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[0].file_path
                  }
                  placeholder={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[2].file_path
                  }
                  // placeholder="./images/tiny.jpg"
                  className="absolute lg:h-[235px] bottom-[10%] lg:bottom-[10%]  h-[300px]  right-[0] rounded-radius10 top-[30%] w-[60%]"
                >
                  {(src) => (
                    <Img
                      src={src}
                      className="absolute lg:h-[235px] bottom-[10%] lg:bottom-[10%]  h-[300px]  right-[0] rounded-radius10 top-[30%] w-[60%]"
                    />
                  )}
                </ProgressiveImage>
              </LazyLoad>
              <LazyLoad offset={300}>
                <ProgressiveImage
                  src={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[3].file_path
                  }
                  placeholder={
                    props.hostLink +
                    "/uploads/" +
                    props.WhoWeAre.images?.[5].file_path
                  }
                  className="absolute  bottom-[0] md:bottom-[0] lg:bottom-[15%] xl:bottom-[5%]  2xl:bottom-[5%] 3xl:bottom-[5%] lg:h-[235px] top-[80%] lg:top-[65%] h-[300px] left-[0] rounded-radius10 w-[49%]"
                >
                  {(src) => (
                    <Img
                      src={src}
                      className="absolute  bottom-[0] md:bottom-[0] lg:bottom-[15%] xl:bottom-[5%]  2xl:bottom-[5%] 3xl:bottom-[5%] lg:h-[235px] top-[80%] lg:top-[65%] h-[300px] left-[0] rounded-radius10 w-[49%]"
                    />
                  )}
                </ProgressiveImage>
              </LazyLoad>
            </Stack>
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={
                  props.hostLink +
                  "/uploads/" +
                  props.WhoWeAre.images?.[6].file_path
                }
                placeholder={
                  props.hostLink +
                  "/uploads/" +
                  props.WhoWeAre.images?.[8].file_path
                }
                className="absolute bottom-[20%]  lg:bottom-[0%] xl:bottom-[12%]  2xl:bottom-[15%] 3xl:bottom-[13%] lg:h-[235px]  h-[300px] right-[9%] rounded-radius10 w-[55%]"
              >
                {(src) => (
                  <Img
                    src={src}
                    className="absolute bottom-[20%]  lg:bottom-[0%] xl:bottom-[12%]  2xl:bottom-[15%] 3xl:bottom-[13%] lg:h-[235px]  h-[300px] right-[9%] rounded-radius10 w-[55%]"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
          </motion.div>
        )}
      </Row>
    </Stack>
  );
}
export default WhoWeAre;
