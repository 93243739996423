import "./animatedCarousel.css";

import ProgressiveImage from "react-progressive-image";
function AnimatedCarousel(props) {
  return (
    <div className=" w-100 " style={{ direction: "ltr" }}>
      <div className="banner-wrap ">
        <div className="banner-slider h-full min-h-[700px]">
          <div className="banner-slide">
            <ProgressiveImage
              src={
                props.hostLink + "/uploads/" + props.Data1.images?.[0].file_path
              }
              placeholder={
                props.hostLink + "/uploads/" + props.Data1.images?.[2].file_path
              }
            >
              {(src) => (
                <div
                  className="hero-image  h-[100%] !bg-cover"
                  style={{
                    background: ` linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${src})`,
                  }}
                ></div>
              )}
            </ProgressiveImage>
            <div className="container mt-[40px]">
              <div className="hero-content">
                <h1
                  className="text-white"
                  data-animation="fadeInUp"
                  data-delay="0.8s"
                >
                  {props.Data1.title}
                </h1>
                <div
                  className="cta-btn"
                  data-animation="fadeInUp"
                  data-delay="1s"
                >
                  <p>{props.Data1.text}</p>
                </div>
              </div>
            </div>
            <div className="hero-overlay"></div>
          </div>
          <div className="banner-slide">
            <ProgressiveImage
              src={
                props.hostLink + "/uploads/" + props.Data2.images?.[0].file_path
              }
              placeholder={
                props.hostLink + "/uploads/" + props.Data2.images?.[2].file_path
              }
            >
              {(src) => (
                <div
                  className="hero-image  h-[100%] !bg-cover"
                  style={{
                    background: ` linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${src})`,
                  }}
                ></div>
              )}
            </ProgressiveImage>
            <div className="container mt-[40px]">
              <div className="hero-content">
                <h1
                  className="text-white"
                  data-animation="fadeInUp"
                  data-delay="0.8s"
                >
                  {props.Data2.title}
                </h1>
                <div
                  className="cta-btn"
                  data-animation="fadeInUp"
                  data-delay="1s"
                >
                  <p>{props.Data2.text}</p>
                </div>
              </div>
            </div>
            <div className="hero-overlay"></div>
          </div>
          <div className="banner-slide">
            <ProgressiveImage
              src={
                props.hostLink + "/uploads/" + props.Data3.images?.[0].file_path
              }
              placeholder={
                props.hostLink + "/uploads/" + props.Data3.images?.[2].file_path
              }
            >
              {(src) => (
                <div
                  className="hero-image  h-[100%] !bg-cover"
                  style={{
                    background: ` linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${src})`,
                  }}
                ></div>
              )}
            </ProgressiveImage>
            <div className="container mt-[40px]">
              <div className="hero-content">
                <h1
                  className="text-white"
                  data-animation="fadeInUp"
                  data-delay="0.8s"
                >
                  {props.Data3.title}
                </h1>
                <div
                  className="cta-btn"
                  data-animation="fadeInUp"
                  data-delay="1s"
                >
                  <p>{props.Data3.text}</p>
                </div>
              </div>
            </div>
            <div className="hero-overlay"></div>
          </div>
          <div className="banner-slide">
            <ProgressiveImage
              src={
                props.hostLink + "/uploads/" + props.Data4.images?.[0].file_path
              }
              placeholder={
                props.hostLink + "/uploads/" + props.Data4.images?.[2].file_path
              }
            >
              {(src) => (
                <div
                  className="hero-image  h-[100%]  !bg-cover"
                  style={{
                    background: ` linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${src})`,
                  }}
                ></div>
              )}
            </ProgressiveImage>
            <div className="container mt-[40px]">
              <div className="hero-content">
                <h1
                  className="text-white"
                  data-animation="fadeInUp"
                  data-delay="0.8s"
                >
                  {props.Data4.title}
                </h1>
                <div
                  className="cta-btn"
                  data-animation="fadeInUp"
                  data-delay="1s"
                >
                  <p>{props.Data4.text}</p>
                </div>
              </div>
            </div>
            <div className="hero-overlay"></div>
          </div>
          <div className="banner-slide">
            <ProgressiveImage
              src={
                props.hostLink + "/uploads/" + props.Data5.images?.[0].file_path
              }
              placeholder={
                props.hostLink + "/uploads/" + props.Data5.images?.[2].file_path
              }
            >
              {(src) => (
                <div
                  className="hero-image  h-[100%] !bg-cover"
                  style={{
                    background: ` linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${src})`,
                  }}
                ></div>
              )}
            </ProgressiveImage>
            <div className="container mt-[40px]">
              <div className="hero-content">
                <h1
                  className="text-white"
                  data-animation="fadeInUp"
                  data-delay="0.8s"
                >
                  {props.Data5.title}
                </h1>
                <div
                  className="cta-btn"
                  data-animation="fadeInUp"
                  data-delay="1s"
                >
                  <p>{props.Data5.text}</p>
                </div>
              </div>
            </div>
            <div className="hero-overlay"></div>
          </div>
          <div className="banner-slide">
            <ProgressiveImage
              src={
                props.hostLink + "/uploads/" + props.Data6.images?.[0].file_path
              }
              placeholder={
                props.hostLink + "/uploads/" + props.Data6.images?.[2].file_path
              }
            >
              {(src) => (
                <div
                  className="hero-image  h-[100%] !bg-cover"
                  style={{
                    background: ` linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${src})`,
                  }}
                ></div>
              )}
            </ProgressiveImage>
            <div className="container mt-[40px]">
              <div className="hero-content">
                <h1
                  className="text-white"
                  data-animation="fadeInUp"
                  data-delay="0.8s"
                >
                  {props.Data6.title}
                </h1>
                <div
                  className="cta-btn"
                  data-animation="fadeInUp"
                  data-delay="1s"
                >
                  <p>{props.Data6.text}</p>
                </div>
              </div>
            </div>
            <div className="hero-overlay"></div>
          </div>
          <div className="banner-slide">
            <ProgressiveImage
              src={
                props.hostLink + "/uploads/" + props.Data7.images?.[0].file_path
              }
              placeholder={
                props.hostLink + "/uploads/" + props.Data7.images?.[2].file_path
              }
            >
              {(src) => (
                <div
                  className="hero-image  h-[100%] !bg-cover"
                  style={{
                    background: ` linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${src})`,
                  }}
                ></div>
              )}
            </ProgressiveImage>
            <div className="container mt-[40px]">
              <div className="hero-content">
                <h1
                  className="text-white"
                  data-animation="fadeInUp"
                  data-delay="0.8s"
                >
                  {props.Data7.title}
                </h1>
                <div
                  className="cta-btn"
                  data-animation="fadeInUp"
                  data-delay="1s"
                >
                  <p>{props.Data7.text}</p>
                </div>
              </div>
            </div>
            <div className="hero-overlay"></div>
          </div>
        </div>
        <div className="shape">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280">
            <path fill="#f6f7fa" fillOpacity="1">
              <animate
                attributeName="d"
                dur="20000ms"
                repeatCount="indefinite"
                values="M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,234.7C672,203,768,149,864,117.3C960,85,1056,75,1152,90.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z; M0,160L48,181.3C96,203,192,245,288,234.7C384,224,480,160,576,133.3C672,107,768,117,864,138.7C960,160,1056,192,1152,197.3C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;												 M0,64L48,74.7C96,85,192,107,288,133.3C384,160,480,192,576,170.7C672,149,768,75,864,80C960,85,1056,171,1152,181.3C1248,192,1344,128,1392,96L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;
													 M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,234.7C672,203,768,149,864,117.3C960,85,1056,75,1152,90.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;"
              />
            </path>
          </svg>
        </div>
      </div>
      <div className="how-to-use"></div>
    </div>
  );
}
export default AnimatedCarousel;
